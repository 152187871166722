import React, { Component } from 'react';
import NewsItem from './NewsItem';
import Spinner from './Spinner';
import InfiniteScroll from "react-infinite-scroll-component";

export class News extends Component {
    articles = [
        {
            "source": {
                "id": null,
                "name": "Livemint"
            },
            "author": "Reuters",
            "title": "Dollar bonds of India's Adani Ports rise as company starts debt buyback | Mint - Mint",
            "description": "US dollar-denominated bonds issued by Adani Ports and and Special Economic Zone after a cash tender offer for buyback of securities",
            "url": "https://www.livemint.com/companies/news/dollar-bonds-of-india-s-adani-ports-rise-as-company-starts-debt-buyback-11682309015846.html",
            "urlToImage": "https://www.livemint.com/lm-img/img/2023/04/24/600x338/ADANI-INDIA-REGULATOR-0_1682310365693_1682310416265.JPG",
            "publishedAt": "2023-04-24T05:57:14Z",
            "content": "The U.S. dollar-denominated bonds issued by India's Adani Ports and Special Economic Zone rose on Monday, after the company commenced a cash tender offer for a buyback of securities. On Monday, Adani… [+1439 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Jagran.com"
            },
            "author": "Prerna Targhotra",
            "title": "Uric Acid Treatment: 5 Foods That You Must Add To Your Diet To Lower Levels Naturally - Jagran English",
            "description": "Uric Acid Treatment According to the Arthritis Foundation 4 percent of American adults are affected by gout It affects about 6 million men and 2 million women in the United States",
            "url": "https://english.jagran.com/lifestyle/uric-acid-treatment-foods-that-you-must-add-to-your-diet-to-lower-levels-naturally-10074790",
            "urlToImage": "https://imgeng.jagran.com/images/2023/apr/foods-for-uric-acid-treatment1682314468300.jpg",
            "publishedAt": "2023-04-24T05:26:00Z",
            "content": "UNHEALTHY lifestyle choices can lead to various health problems. High levels of uric acid are one such health condition caused by sedentary lifestyle choices, unhealthy food choices, sleeping pattern… [+2533 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Financial Express"
            },
            "author": "The Financial Express",
            "title": "Drinking RO water? You are at risk of Vitamin B12 deficiency - The Financial Express",
            "description": null,
            "url": "https://www.financialexpress.com/healthcare/wellness/drinking-ro-water-you-are-at-risk-of-vitamin-b12-deficiency/3059660/",
            "urlToImage": null,
            "publishedAt": "2023-04-24T05:13:36Z",
            "content": null
        },
        {
            "source": {
                "id": null,
                "name": "NDTV News"
            },
            "author": null,
            "title": "Amit Shah vs Asaduddin Owaisi Over Quota For Muslims In Telangana - NDTV",
            "description": "Union Home Minister Amit Shah on Sunday vowed to scrap the reservations for Muslims in Telangana if the BJP forms the government in the state.",
            "url": "https://www.ndtv.com/india-news/muslim-quota-amit-shah-k-chandrasekhar-rao-asaduddin-owaisi-amit-shah-vows-to-scrap-muslim-quota-in-telangana-a-owaisi-responds-3973462",
            "urlToImage": "https://c.ndtvimg.com/2023-04/5b1f2sd_amit-shah_625x300_24_April_23.jpg",
            "publishedAt": "2023-04-24T05:13:13Z",
            "content": "The assembly election in Telangana will be held later this year.\r\nNew Delhi/Hyderabad: Union Home Minister Amit Shah, on Sunday, vowed to scrap reservations for Muslims in Telangana if the BJP forms … [+2891 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "NDTV News"
            },
            "author": null,
            "title": "\"When In Rome\", Slay Like Priyanka Chopra And Team Citadel - NDTV",
            "description": "NDTV.com: India, Business, Bollywood, Cricket, Video and Breaking News",
            "url": "https://www.ndtv.com/",
            "urlToImage": "https://cdn.ndtv.com/common/images/ogndtv.png",
            "publishedAt": "2023-04-24T05:03:48Z",
            "content": null
        },
        {
            "source": {
                "id": null,
                "name": "Livemint"
            },
            "author": "Livemint",
            "title": "India's daily Covid tally sees huge drop, 7,178 cases recorded in 24 hrs | Mint - Mint",
            "description": "The active cases in the country dropped to 65,683 from 67,806.",
            "url": "https://www.livemint.com/news/india/indias-daily-covid-tally-sees-huge-drop-7-178-cases-recorded-in-24-hrs-11682308081720.html",
            "urlToImage": "https://www.livemint.com/lm-img/img/2023/04/24/600x338/RT-PCR_1644552122552_1682308281292.jpg",
            "publishedAt": "2023-04-24T05:01:31Z",
            "content": "India has reported a huge downfall in daily Covid-19 cases as the country logged 7,178 fresh cases in the past 24 hours, according to the Union Health Ministry data updated on Monday. This drop comes… [+1732 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Jara.news"
            },
            "author": "JARA News Media",
            "title": "Why are we drilling into the Earth? Earth Day special - JARA News",
            "description": "Share If you like it India is also part of the international quest initiated by the International Ocean Discovery Program On April 12, 2023, geologists, microbiologists and other scientists will sail to the Atlantis Massif, a 14,000-foot underwater mountain s…",
            "url": "https://jara.news/why-are-we-drilling-into-the-earth-earth-day-special/",
            "urlToImage": "https://i0.wp.com/jara.news/wp-content/uploads/2023/04/1682311982_hqdefault.jpg?resize=480%2C360&ssl=1",
            "publishedAt": "2023-04-24T04:53:20Z",
            "content": "India is also part of the international quest initiated by the International Ocean Discovery ProgramOn April 12, 2023, geologists, microbiologists and other scientists will sail to the Atlantis Massi… [+4792 chars]"
        },
        {
            "source": {
                "id": "espn-cric-info",
                "name": "ESPN Cric Info"
            },
            "author": "ESPNcricinfo staff",
            "title": "SCG unveils gates named after Tendulkar and Lara - ESPNcricinfo",
            "description": "They join Donald Bradman, Alan Davidson and Arthur Morris in being honoured at the iconic ground",
            "url": "https://www.espncricinfo.com/story/scg-unveils-gates-named-after-sachin-tendulkar-and-brian-lara-1371045",
            "urlToImage": "https://img1.hscicdn.com/image/upload/f_auto/lsci/db/PICTURES/CMS/358700/358703.6.jpg",
            "publishedAt": "2023-04-24T04:36:00Z",
            "content": "NewsThey join Donald Bradman, Alan Davidson and Arthur Morris in being honoured at the iconic ground"
        },
        {
            "source": {
                "id": "the-hindu",
                "name": "The Hindu"
            },
            "author": "The Hindu",
            "title": "Giant SpaceX rocket leaves crater, serious damage at Texas base - The Hindu",
            "description": null,
            "url": "https://www.thehindu.com/sci-tech/technology/giant-spacex-rocket-leaves-crater-serious-damage-texas-base/article66772425.ece",
            "urlToImage": null,
            "publishedAt": "2023-04-24T04:36:00Z",
            "content": null
        },
        {
            "source": {
                "id": "the-times-of-india",
                "name": "The Times of India"
            },
            "author": "The Feed",
            "title": "Garena Free Fire MAX redeem codes for today: Details for April 24 exclusive rewards - The Economic Times",
            "description": "We are back with today's Garena Free Fire MAX redeem codes! Keep scrolling to check out.",
            "url": "https://economictimes.indiatimes.com/news/new-updates/garena-free-fire-max-redeem-codes-for-today-details-for-april-24-exclusive-rewards/articleshow/99720754.cms",
            "urlToImage": "https://img.etimg.com/thumb/msid-99720730,width-1070,height-580,imgsize-83248,overlay-economictimes/photo.jpg",
            "publishedAt": "2023-04-24T04:35:00Z",
            "content": "Free Fire Max generally provides players an opportunity to win in-game items such as weapons, diamonds, skins, and more through the use of redeem codes. These codes are composed of 12 digits, consist… [+1757 chars]"
        },
        {
            "source": {
                "id": "the-times-of-india",
                "name": "The Times of India"
            },
            "author": "The Feed",
            "title": "Quordle hints, answer today: Check clues for April 24 word puzzle - Economic Times",
            "description": "Quordle today's answers start with the letters D, B, N, and Y, and end with the letters E, T, Y, and G.",
            "url": "https://economictimes.indiatimes.com/news/new-updates/quordle-hints-answer-today-check-clues-for-april-24-word-puzzle/articleshow/99720216.cms",
            "urlToImage": "https://img.etimg.com/thumb/msid-99720251,width-1070,height-580,imgsize-19050,overlay-economictimes/photo.jpg",
            "publishedAt": "2023-04-24T04:08:00Z",
            "content": "Quordle 455 is here! If you're up for a challenge, then you're in for a treat, as this puzzle is said to be a little more difficult than the previous Quordle games. But don't worry, we've got your ba… [+1446 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "NDTV News"
            },
            "author": null,
            "title": "\"All Parties Welcome\": Wrestlers On #MeToo Protest In Delhi Change Tack - NDTV",
            "description": "India's top wrestlers have resumed their protest in Delhi's Jantar Mantar against Wrestling Federation of India (WFI) chief and BJP MP Brij Bhushan Sharan Singh, accusing him of sexually harassing several athletes.",
            "url": "https://www.ndtv.com/india-news/wrestlers-protest-agains-sexual-harassment-all-parties-welcome-wrestlers-on-metoo-protest-in-delhi-change-tack-3973533",
            "urlToImage": "https://c.ndtvimg.com/2023-04/08li4ot_wrestlers-protest_625x300_24_April_23.jpg",
            "publishedAt": "2023-04-24T04:01:46Z",
            "content": "New Delhi: India's top wrestlers have resumed their protest in Delhi's Jantar Mantar against Wrestling Federation of India (WFI) chief and BJP MP Brij Bhushan Sharan Singh, accusing him of sexually h… [+3777 chars]"
        },
        {
            "source": {
                "id": "the-times-of-india",
                "name": "The Times of India"
            },
            "author": "TIMESOFINDIA.COM",
            "title": "Oops! Ajay Devgn and Kajol's daughter Nysa slips while jumping out of the car, bumps into security guard - Indiatimes.com",
            "description": "Nysa Devgan, the daughter of Kajol and Ajay Devgn, was captured on camera experiencing an oops moment. She collided with a guard as she stepped out of the car. Nysa came to the club with Orry and while stepping out of the vehicle, suddenly a security",
            "url": "https://timesofindia.indiatimes.com/videos/entertainment/hindi/oops-ajay-devgn-and-kajols-daughter-nysa-slips-while-jumping-out-of-the-car-bumps-into-security-guard/videoshow/99719835.cms",
            "urlToImage": "https://timesofindia.indiatimes.com/photo/99719835/size-78178/99719835.jpg",
            "publishedAt": "2023-04-24T03:40:00Z",
            "content": "Nysa Devgan\r\n, the daughter of Kajol\r\n and Ajay Devgn\r\n, was captured on camera experiencing an oops moment. She collided with a guard as she stepped out of the car. Nysa came to the club with Orry a… [+145 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "NDTV News"
            },
            "author": "Asian News International",
            "title": "\"If You Listen To Whatever He Says...\": Ajinkya Rahane Sums Up MS Dhoni's Impact On His Revival - NDTV Sports",
            "description": "In five matches, Ajinkya Rahane has scored 209 runs at an average of 52.25 and an astonishing strike rate of 199.04. He has scored two half-centuries so far, with the best score of 71*.",
            "url": "https://sports.ndtv.com/ipl-2023/if-you-listen-to-whatever-he-says-ajinkya-rahane-sums-up-ms-dhonis-impact-on-his-revival-3973490",
            "urlToImage": "https://c.ndtvimg.com/2023-04/1hh94q0g_ajinkya-rahane-bcci_625x300_24_April_23.jpg?im=FitAndFill,algorithm=dnn,width=1200,height=675",
            "publishedAt": "2023-04-24T03:37:55Z",
            "content": "Following the 49-run win over Kolkata Knight Riders (KKR) in the Indian Premier League (IPL) match, Chennai Super Kings (CSK) batter Ajinkya Rahane served a warning to the other sides after his match… [+3127 chars]"
        },
        {
            "source": {
                "id": "the-times-of-india",
                "name": "The Times of India"
            },
            "author": "etimes.in",
            "title": "Kisi Ka Bhai Kisi Ki Jaan early box office estimates Day 3: Salman Khan starrer sees good growth on Sunda - Indiatimes.com",
            "description": "The report states that the collections have been boosted by the Eid holiday and this trend will remain stable in so",
            "url": "https://timesofindia.indiatimes.com/entertainment/hindi/bollywood/box-office/kisi-ka-bhai-kisi-ki-jaan-early-box-office-estimates-day-3-salman-khan-starrer-sees-good-growth-on-sunday/articleshow/99719427.cms",
            "urlToImage": "https://static.toiimg.com/thumb/msid-99719412,width-1070,height-580,overlay-toi_sw,pt-32,y_pad-40,resizemode-75,imgsize-35706/99719412.jpg",
            "publishedAt": "2023-04-24T03:33:00Z",
            "content": null
        },
        {
            "source": {
                "id": null,
                "name": "Zoom"
            },
            "author": "TN Sports Desk",
            "title": "IPL 2023: CSK Move To the Top, RCB Make Massive Rise - Updated Points Table, Orange and Purple Cap Lists - Times Now",
            "description": "Chennai Super Kings continued their splendid run in the 2023 edition of the IPL as the MS Dhoni-led side won its third game in a row to now find themselves at the top of the table. Other franchises to make significant movements were Royal Challengers Bangalor…",
            "url": "https://www.timesnownews.com/sports/cricket/ipl-2023-csk-move-to-the-top-rcb-make-massive-rise-update-points-table-orange-and-purple-cap-lists-article-99717304",
            "urlToImage": "https://static.tnn.in/thumb/msid-99717304,updatedat-1682306769612,width-1280,height-720,resizemode-75/99717304.jpg",
            "publishedAt": "2023-04-24T03:26:00Z",
            "content": "Chennai Super Kings moved to the top of the points table in IPL 2023, while the RCB duo held on to Orange and Purple Caps | Photo: AP/@RCBtweets"
        },
        {
            "source": {
                "id": null,
                "name": "India Today"
            },
            "author": "Shilpa Nair",
            "title": "I-T raids underway at premises of real estate firm with alleged DMK links in Tamil Nadu - India Today",
            "description": "The Income Tax Department is conducting raids at multiple properties of real state company G Square in Tamil Nadu.",
            "url": "https://www.indiatoday.in/india/story/it-raids-on-properties-of-g-square-at-several-location-in-tamil-nadu-2363789-2023-04-24",
            "urlToImage": "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202304/WhatsApp%20Image%202023-04-24%20at%2008.27.46-sixteen_nine.jpeg?VersionId=ff1bpQxM.8FwQCT.l2ZVQfPoNzECEzYE",
            "publishedAt": "2023-04-24T03:25:09Z",
            "content": "By Shilpa Nair: The Income Tax Department is conducting searches at multiple locations in Tamil Nadu. The searches are being conducted at properties of the real estate company G Square. \r\nThe G Squar… [+372 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Jagran.com"
            },
            "author": "Prerna Targhotra",
            "title": "Foods For Weight Loss: 5 Low-Calories Foods That Can Help You Shed Those Extra Kilos Naturally - Jagran English",
            "description": "Foods For Weight Loss Consuming a diet rich in healthy fibres proteins minerals and low in calories along with regular physical exercise can help you lose significant weight within short span of time Scroll down to know more",
            "url": "https://english.jagran.com/lifestyle/foods-for-weight-loss-low-calories-foods-that-can-help-you-shed-those-extra-kilos-naturally-10074767",
            "urlToImage": "https://imgeng.jagran.com/images/2023/apr/low-calorie-foods1682306796286.jpg",
            "publishedAt": "2023-04-24T03:23:00Z",
            "content": "Weight Loss: Most of us must have this conception that when trying to lose weight, one has to cut down on our daily meals, and the foods we love, control our cravings, exercise daily, have a hardcore… [+2664 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "The New Indian Express"
            },
            "author": "Sudhir Suryawanshi",
            "title": "Maha power tussle: BJP's backing for Ajit against pact with us, says Shinde camp - The New Indian Express",
            "description": "Bharat Gogawale said when the alliance with the BJP was formed,  it was decided that Shinde will remain the chief minister till the next elections in 2024.",
            "url": "https://www.newindianexpress.com/nation/2023/apr/24/maha-power-tussle-bjps-backing-for-ajit-against-pact-with-us-saysshinde-camp-2568802.html",
            "urlToImage": "https://images.newindianexpress.com/uploads/user/imagelibrary/2023/4/22/w600X390/Ajit_Pawar.jpg",
            "publishedAt": "2023-04-24T02:59:00Z",
            "content": "MUMBAI:  Amid talks of an impending collapse of Maharashtras Eknath Shinde-led government and NCP leader Ajit Pawar being annointed the states next chief minister with the blessings of the BJP, the c… [+4185 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Moneycontrol"
            },
            "author": "Moneycontrol News",
            "title": "Reliance Industries Q4 earnings beat estimates: Here's what brokerages say - Moneycontrol",
            "description": "Reliance Industries Q4 result: Gross revenue from operations of India's most valued company came in at Rs 2.39 lakh crore, registering a year-on-year rise of 2.8 percent.",
            "url": "https://www.moneycontrol.com/news/business/stocks/reliance-industries-q4-earnings-beat-estimates-heres-what-brokerages-say-10459251.html",
            "urlToImage": "https://images.moneycontrol.com/static-mcnews/2021/06/RTX1G6H2-770x433.jpg",
            "publishedAt": "2023-04-24T02:56:37Z",
            "content": "Reliance Industries will drive investors' attention on April 24 after the company's beat-the-street March quarter (Q4FY23) earnings declared last week.\r\nReliance Industries reported a net profit, att… [+5374 chars]"
        }
    ]

    constructor(props) {
        super(props);
        this.state = {
            articles: this.articles,
            loading: false,
            // page: 1,
            // totalResults: 0
        }
        // document.title = `${this.capitalizeFirstLetter(this.props.category)} - NewsMonkey`;
    }

    // capitalizeFirstLetter = (string) => {
    //     return string.charAt(0).toUpperCase() + string.slice(1);
    // }


    // async updateNews() {
    //     this.props.setProgress(10);
    //     const url = `https://newsapi.org/v2/top-headlines?country=${this.props.country}&category=${this.props.category}&apiKey=6f5c9b8c22354ecfabd1d1557fce45a2&page=${this.state.page}&pageSize=${this.props.pageSize}`;
    //     let data = await fetch(url);
    //     this.props.setProgress(30);
    //     let parsedData = await data.json();
    //     this.props.setProgress(70);
    //     console.log(parsedData);
    //     this.setState({
    //         articles: parsedData.articles,
    //         totalResults: parsedData.totalResults,
    //         loading: false
    //     })
    //     this.props.setProgress(100);
    // }

    // async componentDidMount() {

    //     this.updateNews();
    //     this.loading = false
    // }

    // handlePrevClick = async () => {
    //     this.setState(
    //         {
    //             page: this.state.page - 1
    //         }
    //     )
    //     this.updateNews();
    // }

    // handleNextClick = async () => {
    //     this.setState(
    //         {
    //             page: this.state.page + 1
    //         }
    //     )
    //     this.updateNews();
    // }

    // fetchMoreData = async () => {
    //     this.setState({page: this.state.page + 1})
    //     const url = `https://newsapi.org/v2/top-headlines?country=${this.props.country}&category=${this.props.category}&apiKey=6f5c9b8c22354ecfabd1d1557fce45a2&page=${this.state.page}&pageSize=${this.props.pageSize}`;
    //     let data = await fetch(url);
    //     let parsedData = await data.json();
    //     console.log("ccccc", parsedData);
    //     this.setState({
    //         articles: this.state.articles.concat(parsedData.articles),
    //         totalResults: parsedData.totalResults,

    //     })
    // };

    render() {
        return (
            <div id="scrollableDivLLL" className="container">
                <h2 className="my-3 text-center">NewsMonkey - Top Headlines</h2>
                    <div className="row">
                        {this.state.articles.map((element) => {
                            return <div className="col-md-4" key={element.url}>
                                <NewsItem title={element.title ? element.title : ""} description={element.description ? element.description : ""} imageUrl={element.urlToImage} newsUrl={element.url} author={element.author} date={element.publishedAt} />
                            </div>
                            
                        })}
                        
                    </div>
                {/* </InfiniteScroll> */}
                {/* <div className="container d-flex justify-content-between">
                    <button type="button" disabled={this.state.page <= 1} className="btn btn-dark" onClick={this.handlePrevClick}> &larr; Previous</button>
                    <button disabled={this.state.page + 1 > Math.ceil(this.state.totalResults / this.props.pageSize)} type="button" className="btn btn-dark" onClick={this.handleNextClick}>Next &rarr;</button>
                </div> */}
                <p>&nbsp;</p>
            </div>
        )
    }
}

export default News

